<template>
<div class="main absolute block w-screen h-screen">
    <div class=" bg-white mx-auto mt-32 rounded-3xl overflow-hidden w-10/12">
        <div class="header w-full">
                <div
                    class=" absolute
                    rounded-t-3xl
                    bg-gradient-to-r from-blue-900 to-gray-800
                    p-4
                    shadow
                    w-10/12
                    text-2xl text-white
                    "
                >
                    <div class="absolute inline-table left-0 ml-5">
                        <i class="mx-auto w-10 relative block my-auto"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M416 176C416 78.8 322.9 0 208 0S0 78.8 0 176c0 39.57 15.62 75.96 41.67 105.4c-16.39 32.76-39.23 57.32-39.59 57.68c-2.1 2.205-2.67 5.475-1.441 8.354C1.9 350.3 4.602 352 7.66 352c38.35 0 70.76-11.12 95.74-24.04C134.2 343.1 169.8 352 208 352C322.9 352 416 273.2 416 176zM269.8 218.3C266.2 239.2 250.4 252.1 228 257.7V268c0 11.03-8.953 20-20 20s-20-8.969-20-20V257.2c-8.682-1.922-17.3-4.723-25.06-7.512l-4.266-1.5C148.3 244.5 142.8 233.1 146.5 222.7c3.688-10.41 15.11-15.81 25.52-12.22l4.469 1.625c7.844 2.812 16.72 6 23.66 7.031C213.8 221.3 229 219.3 230.4 211.5C231.3 206.4 231.8 203.6 202.5 195.2L196.7 193.5c-17.33-5.094-57.92-17-50.52-59.84C149.8 112.8 165.6 98.76 188 93.99V84c0-11.03 8.953-20 20-20s20 8.969 20 20v10.63c5.453 1.195 11.34 2.789 18.56 5.273C257 103.5 262.5 114.9 258.9 125.4C255.3 135.8 243.8 141.3 233.4 137.7c-5.859-2.031-12-4-17.59-4.844C202.2 130.8 186.1 132.7 185.6 140.5C184.8 144.1 184.3 148.2 207.1 155.2L213.5 156.8C235.8 163.1 277.3 175 269.8 218.3zM599.6 443.7C624.8 413.9 640 376.6 640 336C640 238.8 554 160 448 160c-.3145 0-.6191 .041-.9336 .043C447.5 165.3 448 170.6 448 176c0 98.62-79.68 181.2-186.1 202.5C282.7 455.1 357.1 512 448 512c33.69 0 65.32-8.008 92.85-21.98C565.2 502 596.1 512 632.3 512c3.059 0 5.76-1.725 7.02-4.605c1.229-2.879 .6582-6.148-1.441-8.354C637.6 498.7 615.9 475.3 599.6 443.7z"></path></svg></i>
                    </div>
                    <h3 class=" relative inline-block font-bold">Chat de Observaciones</h3>
                    <div class="absolute inline-table right-0 mr-10">
                        <button type="button" data-dismiss="modal" @click="closeModalChatObservComp">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        <div class=" body p-5">
            <div class="messaging">
                <div class="inbox_msg">
                    <div class="mesgs rounded-3xl border-2 border-solid border-blue-900 w-full h-96 mt-16 overflow-hidden">
                        <div class="msg_history border-b-2 border-solid border-blue-900 h-80 overflow-y-scroll">
                            <div v-for="(chat, index) in ChatObservaciones" :key="index">
                                <div v-if="chat.esCartera == false">
                                    <div class="incoming_msg p-1">
                                        <div class="received_msg">
                                            <div class="received_withd_msg w-6/12">
                                                <span class="userchat font-bold text-blue-900 text-right relative block">{{ chat.usuarioIngreso }}</span>
                                                <p class=" relative block text-black text-left bg-gray-300 p-2 rounded-xl">{{ chat.observacion }}</p>
                                                <span class="time_date relative block text-right">{{ chat.fechaIngreso }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="outgoing_msg">
                                        <div class=" relative inline-block block right-0 w-6/12">
                                            
                                        </div>
                                        <div class="sent_msg relative inline-block right-0 w-6/12">
                                            <span class="userchat font-bold text-blue-900 text-left relative block">{{ chat.usuarioIngreso }}</span>
                                            <p class=" relative block text-white text-left bg-gradient-to-r from-blue-900 to-gray-800 p-2 rounded-xl">{{ chat.observacion }}</p>
                                            <span class="time_date relative block text-left">{{ chat.fechaIngreso }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="type_msg bg-gray-200">
                            <div class="input_msg_write">
                                <input @keypress.enter="postComment" v-model="Observacion" type="text" class=" bg-gray-200 text-black my-4 w-10/12 " placeholder="Escribe un mensaje..." />
                                <button @click="postComment" class="text-white relative inline-block md:ml-32 right-0 bg-gradient-to-r from-blue-900 to-gray-800 rounded-full pr-1 w-10 h-10 " type="button"><i class="fas fa-paper-plane"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import auth from "@/logic/auth";
import { mapMutations, mapState } from 'vuex';
export default {
    name: "ChatObservaciones",
    data() {
        return {
            Observacion: "",

            DetCobranza: {}
        }
    },            
    methods: {
        ...mapMutations(["closeModalChatObservComp", "openModalChatObservComp"]),
        postComment() {
            if(this.Observacion == "" || this.Observacion == undefined || this.Observacion.length < 10){
                this.$alert(
                    "Por favor ingresa un mensaje para enviar",
                    "Sin Texto en Mensaje!",
                    "error"
                );
            }else{
                var user = auth.getUserLogged();
                user = JSON.parse(user);
                this.DetCobranza = {
                    idCobranza: this.ChatObservaciones[0].idCobranza,
                    fechaIngreso: new Date(),
                    usuarioIngreso: user.loginUsuario,
                    observacion: this.Observacion,
                    EsCartera: this.permisosUsuario.cc_Cobranza,
                }
                axios
                .post(this.$hostname + "/Api/DetCobranza", this.DetCobranza, {
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    //console.log(result)
                    //console.log(this.DetCobranza)
                    //console.log("enviado")
                    axios
                    .get(this.$hostname + "/Api/DetCobranza/GetDetCobranzaSP/" + this.ChatObservaciones[0].idCobranza, {
                        headers: {
                            "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                    })
                    .then((result) => {
                        //console.log(result.data)
                        this.openModalChatObservComp(result.data)
                        this.Observacion = ""
                    })
                    .catch(err => {
                        console.log(err)
                        this.$alert(
                            "Por favor vuelve a ingresar a la plataforma",
                            "Expiro la Sesion!",
                            "error"
                        );
                        this.$router.push("/");
                    });
                })
                .catch((err) => {
                    console.log(err)
                    //console.log(this.EncCobranza)
                    //console.log(this.DetCobranza)
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
            }

        },
    },
    created() { console.log(this.permisosUsuario) },
    computed: {
        ...mapState(["ChatObservaciones","permisosUsuario"])
    }
}
</script>

<style>
.main{
    background: rgba(0, 0, 0, 0.5);
}
</style>
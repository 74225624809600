<template>
    <div class="cont_Main">
        <div id="cont_CC" 
             class=" rounded-lg overflow-hidden border border-gray-300 overflow-x-scroll">
            <table id="table_CuentaCorriente" 
                   class=" relative table-auto w-full">
                <thead class=" relative block bg-blue-900 text-white border-collapse">
                    <tr class=" grid grid-cols-11 relative table mx-auto">
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">TipoDcto</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Cmp.Tran</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Cmp.Ing</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Fec.Ing</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Fec.Transac</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Banco</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">NumeroDcto</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Valor</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Saldo</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Descuento</th>
                        <th class=" inline-block w-full text-center 2xl:w-28 py-2">Concepto</th>
                    </tr>
                </thead>
                <tbody class=" grid relative block overflow-y-scroll overflow-x-hidden max-h-96">
                    <tr class=" grid grid-cols-11 relative table border border-t-gray-300 " v-for=" CC in EncCuentaCorriente" :key="CC.registroNo" :class="CC.tipoDcto">
                        <td class=" relative inline-block w-full py-2">{{ CC.tipoDcto + CC.tipoPlataforma }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.campañaTransaccion }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.campañaIngreso }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.fecha_ingreso }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.fecha_transaccion }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.banco }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.numeroDocumento }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.valorTotal }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.saldo }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.dctoIntercambio }}</td>
                        <td class=" relative inline-block w-full py-2">{{ CC.concepto }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CuentaCorriente',
    props: {
        EncCuentaCorriente: {},
    },
    data(){
        return {
            
        }
    },
}
</script>

<style>
#table_CuentaCorriente{
    min-width: 1000px;
}
.FAC, .NSI{
    color: hsl(0, 100%, 50%);
}
.NCD, .NDE{
    color: hsl(110, 100%, 31%);
}
.NCC{
    color: hsl(300, 100%, 25%);
}
.PAG, .ANT{
    color: hsl(230, 100%, 50%);
}
.NCS{
    color: hsl(39, 100%, 43%);
}
.NOV{
    color: hsl(0, 0%, 36%);
}
.APR{
    color: hsl(0, 59%, 41%);
}
</style>
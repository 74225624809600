<template>
    <div id="cont_Main">
        <div id="cont_CC relative" 
             class=" rounded-lg overflow-hidden border border-gray-300 overflow-x-scroll">
            <table class="table_GridCobranzas table-auto w-min md:w-full">
                <thead class=" relative block bg-blue-900  text-white border-collapse">
                    <tr class="grid grid-cols-11 relative table mx-auto">
                        <th class=" w-full py-2">Nro.Gest</th>
                        <th class=" w-full py-2">Fec.Ing</th>
                        <th class=" w-full py-2">Nro.Fact</th>
                        <th class=" w-full py-2">Cmp.Fact</th>
                        <th class=" col-span-2 w-full py-2">Motivo</th>
                        <th class=" col-span-2 w-full py-2">Observaciones</th>
                        <th class=" w-full py-2">Comp.</th>
                        <th class=" w-full py-2">Fec.Comp</th>
                        <th class=" w-full py-2">Cumplió</th>
                        <!--<th class=" w-full py-2">Acciones</th>-->
                    </tr>
                </thead>
                <tbody class=" grid relative block overflow-y-scroll overflow-x-auto max-h-96">
                    <tr class="grid grid-cols-11 relative border border-t-gray-300 table" v-for=" (Enc,index) in EncCobranza" :key="index">
                        <td class=" w-full py-2">{{ Enc.numCodigo }}</td>
                        <td class=" w-full py-2">{{ Enc.fechaCreacion }}</td>
                        <td class=" w-full py-2">{{ Enc.numFactura }}</td>
                        <td class=" w-full py-2">{{ Enc.campañaFactura }}</td>
                        <td class=" col-span-2 w-full py-2">
                            <div v-if="permisosUsuario.cc_Cobranza" class="cont_motivoGestion cursor-pointer" @click="AbrirEditarMotivo(Enc.numCodigo,Enc.motivo)">
                                <span class="w-44">{{ Enc.motivo }}</span>
                                <div class="btn_editarMotivo text-blue-900">
                                    <i class="fas fa-edit"></i>
                                </div>
                            </div>
                            <div v-else>{{ Enc.motivo }}</div>
                        </td>
                        <td class=" col-span-2 w-full py-2"><i class="cursor-pointer mx-auto w-7 relative block" @click="openChatObservComp(Enc.numCodigo)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#1e3a8a" d="M416 176C416 78.8 322.9 0 208 0S0 78.8 0 176c0 39.57 15.62 75.96 41.67 105.4c-16.39 32.76-39.23 57.32-39.59 57.68c-2.1 2.205-2.67 5.475-1.441 8.354C1.9 350.3 4.602 352 7.66 352c38.35 0 70.76-11.12 95.74-24.04C134.2 343.1 169.8 352 208 352C322.9 352 416 273.2 416 176zM269.8 218.3C266.2 239.2 250.4 252.1 228 257.7V268c0 11.03-8.953 20-20 20s-20-8.969-20-20V257.2c-8.682-1.922-17.3-4.723-25.06-7.512l-4.266-1.5C148.3 244.5 142.8 233.1 146.5 222.7c3.688-10.41 15.11-15.81 25.52-12.22l4.469 1.625c7.844 2.812 16.72 6 23.66 7.031C213.8 221.3 229 219.3 230.4 211.5C231.3 206.4 231.8 203.6 202.5 195.2L196.7 193.5c-17.33-5.094-57.92-17-50.52-59.84C149.8 112.8 165.6 98.76 188 93.99V84c0-11.03 8.953-20 20-20s20 8.969 20 20v10.63c5.453 1.195 11.34 2.789 18.56 5.273C257 103.5 262.5 114.9 258.9 125.4C255.3 135.8 243.8 141.3 233.4 137.7c-5.859-2.031-12-4-17.59-4.844C202.2 130.8 186.1 132.7 185.6 140.5C184.8 144.1 184.3 148.2 207.1 155.2L213.5 156.8C235.8 163.1 277.3 175 269.8 218.3zM599.6 443.7C624.8 413.9 640 376.6 640 336C640 238.8 554 160 448 160c-.3145 0-.6191 .041-.9336 .043C447.5 165.3 448 170.6 448 176c0 98.62-79.68 181.2-186.1 202.5C282.7 455.1 357.1 512 448 512c33.69 0 65.32-8.008 92.85-21.98C565.2 502 596.1 512 632.3 512c3.059 0 5.76-1.725 7.02-4.605c1.229-2.879 .6582-6.148-1.441-8.354C637.6 498.7 615.9 475.3 599.6 443.7z"/></svg></i></td>
                        <td class=" w-full py-2">{{ Enc.compromisoPago ? 'SI' : 'NO' }}</td>
                        <td class=" w-full py-2">{{ Enc.fechaCompromiso }}</td>
                        <td class=" w-full py-2">{{ Enc.cumplioPago ? 'SI' : 'NO' }}</td>
                        <!--<td class=" w-full py-2"><i class=" cursor-pointer fas fa-pencil-alt mr-3"></i></td>-->
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="editarMotivo" class="cont_editarMotivo">
            <div class="cont_Formulario_EditarMotivo">
                <div class="cont_Header_editarMotivo">
                    <span>Cambiar Motivo de Cobranza #{{ idCobranzaAEditar }}</span>
                    <i class="fas fa-times-circle" @click="CerrarEditarMotivo()"></i>
                </div>
                <div class="cont_Body_editarMotivo">
                    <span class="font-bold">Motivo Actual: </span><span>{{ descMotivoAEditar }}</span>
                    <span class="font-bold">Nuevo Motivo:</span> 
                    <select
                        id="buscarzona"
                        v-model="nuevoMotivo"
                        class="border rounded-xl shadow h-9 bg-white w-full text-center"
                    >
                        <option disabled>Motivos Corbanza</option>
                        <option v-for="motivo in MotivosLLamadasCobranza" :value="motivo" :key="motivo.numCodigo">
                            {{ motivo.descripcionMotivo }}
                        </option>
                    </select>
                    <button class="flex 
                        w-full
                        bg-green-600
                        rounded-lg
                        border-solid
                        border-green-500
                        border-b-2
                        p-2
                        text-white
                        font-bold"
                        @click="cambiarMotivoCobranza()">
                        <i class="fas fa-save"></i>Cambiar Motivo
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth";
import { mapState,mapMutations } from 'vuex'
export default {
    name: 'GridCobranzas',
    props: {
        numFactura: 0,
    },
    data(){
        return{
            editarMotivo: false,
            idCobranzaAEditar: 0,
            descMotivoAEditar: "",
            nuevoMotivo: {},
        }
    },
    methods: {
        ...mapMutations(["openModalChatObservComp","openModalCargando","closeModalCargando","setEncCobranza"]),
        openChatObservComp(idCobranza){
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/DetCobranza/GetDetCobranzaSP/" + idCobranza, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    //console.log(result.data)
                    if(result.data.length != 0){
                        this.openModalChatObservComp(result.data)
                    }else{
                        this.$alert(
                            "Por favor vuelve a ingresar a la plataforma",
                            "Expiro la Sesion!",
                            "error"
                        );
                        this.$router.push("/");
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        AbrirEditarMotivo(idCobranza,descMotivo){
            this.editarMotivo = true
            this.idCobranzaAEditar = idCobranza
            this.descMotivoAEditar = descMotivo
        },
        CerrarEditarMotivo(){
            this.editarMotivo = false
            this.idCobranzaAEditar = 0
            this.descMotivoAEditar = ""
            this.nuevoMotivo = {}
        },
        cambiarMotivoCobranza(){
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/MotivosLlamadasCobranza/ActualizarMotivoCobranza/" + this.idCobranzaAEditar + "/" + this.nuevoMotivo.numCodigo, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.$alert(
                        result.data[0].resultado,
                        "Motivo cambiado!",
                        "success"
                    )
                    this.cargarGestiones()
                    this.CerrarEditarMotivo()
                    this.closeModalCargando()
                })
                .catch((err) => {
                    console.log(err);
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        cargarGestiones(){
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/EncCobranza/GetEncCobranzaSP/" + this.numFactura, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.setEncCobranza(result.data)
                    this.closeModalCargando()
                })
                .catch(err => {
                    console.log(err)
                    this.closeModalCargando()
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        }
    },
    created(){
        this.cargarGestiones()
    },
    computed: {
        ...mapState(["MotivosLLamadasCobranza","EncCobranza","permisosUsuario"]),
    }
}
</script>

<style>
    .table_GridCobranzas{
        min-width: 800px;
    }
    td {
        display: flex;
        align-items: center;
        justify-items: center;
        place-content: center;
    }
    .cont_motivoGestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
    }
    .cont_editarMotivo {
        position: fixed;
        display: flex;
        top: 0;
        right: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
    }
    .cont_Formulario_EditarMotivo {
        background: #fff;
        border-radius: 2rem;
        overflow: hidden;
    }
    .cont_Header_editarMotivo {
        display: flex;
        background: linear-gradient(90deg, #1e3a8a, #1f2937);
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        padding: 0.5rem 3.5rem;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .cont_Header_editarMotivo i {
        margin: 0rem 23rem;
        position: absolute;
        cursor: pointer;
    }
    .cont_Body_editarMotivo {
        padding: 1rem 2rem 1.5rem 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .cont_Body_editarMotivo span {
        margin: 0.2rem 0rem;
    }
    .cont_Body_editarMotivo button {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .cont_Body_editarMotivo button i {
        position: absolute;
        display: block;
        padding: 0rem 0rem;
        width: 22rem;
        text-align: left;
    }
</style>

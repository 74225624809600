<template>
<div class="main absolute block w-screen h-screen">
    <!-- Modal content-->
    <div>

        <div class=" bg-white mx-auto mt-32 rounded-3xl overflow-hidden w-10/12">
            <div class="header w-full">
                    <div
                        class=" absolute
                        rounded-t-3xl
                        bg-gradient-to-r from-blue-900 to-gray-800
                        p-4
                        shadow
                        w-10/12
                        text-2xl text-white
                        "
                    >
                        <div class="absolute inline-table left-0 ml-5">
                            <i class="fas fa-address-book mx-auto my-auto"></i>
                        </div>
                        <h3 class=" relative inline-block font-bold">Informacion Personal</h3>
                        <div class="absolute inline-table right-0 mr-10">
                            <button type="button" data-dismiss="modal" @click="closeModalInfoCliente">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            <div class=" body p-5">
                <div id="cont_id" 
                    class=" mt-16
                            grid 
                            grid-cols-2
                            md:grid-cols-3
                            gap-2
                            md:gap-6
                            overflow-y-scroll
                            h-96">
                    <div id="cont_Departamento" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Departamento:</span> 
                        <input type="text" 
                            id="Departamento" 
                            v-model="Departamento"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md"
                            placeholder="Departamento" disabled>
                    </div>
                    <div id="cont_Ciudad" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Ciudad:</span> 
                        <input type="text" 
                            id="Ciudad" 
                            v-model="Ciudad" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Ciudad" 
                            disabled>
                    </div>
                    <div id="cont_Poblacion" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Poblacion:</span> 
                        <input type="text" 
                            id="Poblacion" 
                            v-model="Poblacion"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Poblacion" disabled>
                    </div>
                    <div id="cont_Barrio" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Barrio:</span> 
                        <input type="text" 
                            id="Barrio" 
                            v-model="Barrio" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Barrio" 
                            disabled>
                    </div>
                    <div id="cont_Teléfono1" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Teléfono 1:</span> 
                        <input type="number" 
                            id="Teléfono1" 
                            v-model="Telefono1" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfono 1" 
                            disabled>
                    </div>
                    <div id="cont_Teléfono2" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Teléfono 2:</span> 
                        <input type="text" 
                            id="Teléfono2" 
                            v-model="Telefono2"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfono 2" disabled>
                    </div>
                    <div id="cont_Direccion" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Direccion:</span> 
                        <input type="text" 
                            id="Direccion" 
                            v-model="Direccion"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Direccion" disabled>
                    </div>
                    <div id="cont_NombreCodeudor" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3
                                mt-4">
                        <span class=" relative block w-full text-left">Nombre Codeudor:</span> 
                        <input type="text" 
                            id="NombreCodeudor" 
                            v-model="NombreCodeudor" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Codeudor" 
                            disabled>
                    </div>
                    <div id="cont_TeléfonosCodeudor" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Teléfonos Codeudor:</span> 
                        <input type="text" 
                            id="TeléfonosCodeudor" 
                            v-model="TelefonosCodeudor" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfonos Codeudor" 
                            disabled>
                    </div>
                    <div id="cont_NombreReferenciaFamiliar1" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3
                                mt-4">
                        <span class=" relative block w-full text-left">Nombre Referencia Familiar 1:</span> 
                        <input type="text" 
                            id="NombreReferenciaFamiliar1" 
                            v-model="NombreReferenciaFamiliar1" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Referencia Familiar 1" 
                            disabled>
                    </div>
                    <div id="cont_TeléfonosReferenciaFamiliar1" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Teléfonos Referencia Familiar 1:</span> 
                        <input type="text" 
                            id="TeléfonosReferenciaFamiliar1" 
                            v-model="TelefonosReferenciaFamiliar1" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfonos Referencia Familiar 1" 
                            disabled>
                    </div>
                    <div id="cont_NombreReferenciaFamiliar2" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3
                                mt-4">
                        <span class=" relative block w-full text-left">Nombre Referencia Familiar 2:</span> 
                        <input type="text" 
                            id="NombreReferenciaFamiliar2" 
                            v-model="NombreReferenciaFamiliar2" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Referencia Familiar 2" 
                            disabled>
                    </div>
                    <div id="cont_TeléfonosReferenciaFamiliar2" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Teléfonos Referencia Familiar 2:</span> 
                        <input type="text" 
                            id="TeléfonosReferenciaFamiliar2" 
                            v-model="TelefonosReferenciaFamiliar2" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfonos Referencia Familiar 2" 
                            disabled>
                    </div>
                    <div id="cont_NombreReferenciaPersonal1" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3
                                mt-4">
                        <span class=" relative block w-full text-left">Nombre Referencia Personal 1:</span> 
                        <input type="text" 
                            id="NombreReferenciaPersonal1" 
                            v-model="NombreReferenciaPersonal1" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Referencia Personal 1" 
                            disabled>
                    </div>
                    <div id="cont_TeléfonosReferenciaPersonal1" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Teléfonos Referencia Personal 1:</span> 
                        <input type="text" 
                            id="TeléfonosReferenciaPersonal1" 
                            v-model="TelefonosReferenciaPersonal1" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfonos Referencia Personal 1" 
                            disabled>
                    </div>
                    <div id="cont_NombreReferenciaPersonal2" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3
                                mt-4">
                        <span class=" relative block w-full text-left">Nombre Referencia Personal 2:</span> 
                        <input type="text" 
                            id="NombreReferenciaPersonal2" 
                            v-model="NombreReferenciaPersonal2" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Referencia Personal 2" 
                            disabled>
                    </div>
                    <div id="cont_TeléfonosReferenciaPersonal2" 
                        class="relative 
                                inline-block
                                col-span-2
                                md:col-span-3">
                        <span class=" relative block w-full text-left">Teléfonos Referencia Personal 2:</span> 
                        <input type="text" 
                            id="TeléfonosReferenciaPersonal2" 
                            v-model="TelefonosReferenciaPersonal2" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Teléfonos Referencia Personal 2" 
                            disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import auth from "@/logic/auth";
import { mapMutations, mapState } from 'vuex';
export default {
    name: "InfoCliente",
    props: {
        InformacionCliente: {}
    },
    data() {
        return {

        }
    },
    methods: {
        ...mapMutations(["closeModalInfoCliente"]),
        setInfoCliente(){
            this.NombreCodeudor = this.InfoCliente.nombreCodeudor
            this.TelefonosCodeudor = this.InfoCliente.telefonosCodeudor
        },
    },
    computed:  {
        Departamento(){
            return this.InformacionCliente.departamento
        },
        Poblacion(){
            return this.InformacionCliente.poblacion
        },
        Ciudad(){
            return this.InformacionCliente.ciudad
        },
        Barrio(){
            return this.InformacionCliente.barrio
        },
        Telefono1(){
            return this.InformacionCliente.telefono1
        },
        Telefono2(){
            return this.InformacionCliente.telefono2
        },
        Direccion(){
            return this.InformacionCliente.direccionResidencia
        },
        NombreCodeudor(){
            return this.InformacionCliente.nombreCodeudor
        },
        TelefonosCodeudor(){
            return this.InformacionCliente.telefonosCodeudor
        },
        NombreReferenciaFamiliar1(){
            return this.InformacionCliente.nombreRF1
        },
        TelefonosReferenciaFamiliar1(){
            return this.InformacionCliente.telefonosRF1
        },
        NombreReferenciaFamiliar2(){
            return this.InformacionCliente.nombreRF2
        },
        TelefonosReferenciaFamiliar2(){
            return this.InformacionCliente.telefonosRF2
        },
        NombreReferenciaPersonal1(){
            return this.InformacionCliente.nombreRP1
        },
        TelefonosReferenciaPersonal1(){
            return this.InformacionCliente.telefonosRP1
        },
        NombreReferenciaPersonal2(){
            return this.InformacionCliente.nombreRP2
        },
        TelefonosReferenciaPersonal2(){
            return this.InformacionCliente.telefonosRP2
        },
    },
    created(){
        //console.log(this.InformacionCliente)
    }
}
</script>

<style>
.main{
    background: rgba(0, 0, 0, 0.5);
}
</style>